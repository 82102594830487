import { keyframes } from '@mui/material';

export const blink = keyframes`
  50% {
    background: rgba(247, 25, 25, 0.4);
  }
`;

export const progressBarStripes = keyframes`
  from { background-position: 1rem 0 }
  to { background-position:0 0 }
`;
